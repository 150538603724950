import React from 'react';
import Slider from "react-slick";
import { RichText } from 'prismic-reactjs';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={ className }
      style={ { ...style, right: '25px', zIndex: 1000, display: "block" } }
      onClick={ onClick }
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={ className }
      style={ { ...style, left: '25px', zIndex: 1000, display: "block" } }
      onClick={ onClick }
    />
  );
}


const ImageCarousel = (section) => {

  const { items } = section;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const captionStyle = {
    position: 'absolute',
    overflow: 'auto',
    zIndex: 10000,
    bottom: '30px',
    right: '30px',
  }

  return (
    <div className='slice-image-full'>
      <Slider { ...settings }>
        { items.map((item, index) => {

          const { image_caption_subtitle: subtitle, image_caption_title: title, background_color: color, text_color: textColor } = item;

          return (
            <div key={ index }>
              <div style={ { position: 'relative', overflow: 'auto', backgroundColor: color, color: textColor } }>
                <div className='caption' style={ { ...captionStyle, color: textColor } }>
                  { RichText.render(title) }
                  { RichText.render(subtitle) }
                </div>
                <img width="100%" src={ item.image.url } alt={ item.image.alt } />
              </div>
            </div>
          )
        }
        )
        }
      </Slider>
    </div >
  );
};

export default ImageCarousel;
