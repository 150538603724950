import React from 'react';
import { RichText } from 'prismic-reactjs';
// import { axios } from 'axios';

import './instagram.scss';

// eslint-disable-next-line
// function getGrams(access_token) {
//   axios.get(this.url + "?access_token=" + access_token)
//       .then(({ data }) => {
//           this.grams = data.data;
//           this.username = data.data[0].user.username;
//           this.next_url = data.pagination.next_url;
//       })
//       .catch((error) => {
//           console.log("error with " + this.access_token);
//           console.log(error);
//           this.error = true;
//       });
// }

// eslint-disable-next-line
// function getMoreGrams() {
//   axios.get(this.next_url)
//       .then(({ data }) => {
//           this.grams = this.grams.concat(data.data);
//           this.next_url = data.pagination.next_url;
//       })
//       .catch((error) => {
//           console.log(error);
//           this.error = true;
//       });
// }


const Instagram = (section) => {

  // eslint-disable-next-line
  const settings = {
    access_token: "",
    url: "https://api.instagram.com/v1/users/self/media/recent/",
    username: "",
    grams: [],
    next_url: "",
    error: false
  }

  // eslint-disable-next-line
  const instapage = (section) => {
    return "https://www.instagram.com/" + this.username;
  }

  const captionStyle = {
    fontSize: '12px',
    textAlign: 'center'
  }


  return (
    <div className='instagram-section'>
        <a 
          href='/'
          className="title"
          style={captionStyle}
        >
          {/* { RichText.render(section.primary.headline) } */}
        </a>
    </div >
  );
};

export default Instagram;
