import React from 'react';
import Logo from './Logo';

import './footer.scss';

const MCForm = () => (
  <div id="mc_embed_signup">
    <form action="https://layer8.us19.list-manage.com/subscribe/post?u=3a39c5609a51569ffa4289d85&amp;id=87bc6b15b8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>

      <div id="mc_embed_signup_scroll">
        <label className="type-semibold">Join Our Mailing List</label>
        <div className="mc-field-group">
          <input
            type="email"
            name="EMAIL"
            className="required email"
            placeholder="Enter your email address"
            id="mce-EMAIL" />
        </div>

        <div id="mce-responses" className="clear">
          <div className="response" id="mce-error-response" style={ { display: 'none' } }></div>
          <div className="response" id="mce-success-response" style={ { display: 'none' } }></div>
        </div>

        <div style={ { position: 'absolute', left: '-5000px' } } aria-hidden="true">
          <input type="text" name="b_3a39c5609a51569ffa4289d85_87bc6b15b8" tabIndex="-1" />
        </div>

        <div className="clear">
          <input type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" className="submit-button" />
        </div>
      </div>
    </form>
  </div>
)


const Footer = () =>

  <footer className="footer-section footer-text">
    <div className="footer-section-contact">
      <Logo />
      <MCForm />
      <p className="footer-section-legal">© 2019 AL1VE APPAREL. ALL RIGHTS RESERVED </p>
    </div>
  </footer>

export default Footer;
