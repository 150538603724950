import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Preview from './Preview';
import Page from './Page';
import NotFound from './NotFound';

import Header from './components/Header';
import Footer from './components/Footer';

const App = (props) => (
  <Router>
    < >
      <Header />
      <Switch>
        <Redirect exact from="/" to="/collection" />
        <Route exact path="/preview" render={ routeProps => <Preview { ...routeProps } prismicCtx={ props.prismicCtx } /> } />
        <Route exact path="/:uid" render={ routeProps => <Page { ...routeProps } prismicCtx={ props.prismicCtx } /> } />
        <Route component={ NotFound } />
      </Switch>
      <Footer />
    </ >
  </Router>
);

export default App;
