import React from 'react';
import { RichText } from 'prismic-reactjs';

const TextSection = (section) => {

  const { heading, body1, background_color: color } = section.primary;

  return (
    <div className='text-section' style={ { backgroundColor: color } }>
      { RichText.render(heading) }
      { RichText.render(body1) }
    </div>
  );
};

export default TextSection;
