export default {

  apiEndpoint: 'https://al1ve.prismic.io/api/v2',

  // -- Access token if the Master is not open
  accessToken: 'MC5YWkZXekJJQUFDSUFZQVQ1.77-977-977-977-9Xjfvv73vv73vv70e77-9Sg0377-9ZO-_vWjvv73vv71D77-977-9Kjzvv73vv71X77-9XO-_ve-_vQ',

  // OAuth
  // clientId: 'xxxxxx',
  // clientSecret: 'xxxxxx',

  // -- Links resolution rules
  // This function will be used to generate links to Prismic.io documents
  // As your project grows, you should update this function according to your routes
  linkResolver(doc) {
    if (doc.type === 'page') return `/${doc.uid}`;
    return '/';
  },
};
