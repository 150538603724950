import React from 'react';
import { RichText } from 'prismic-reactjs';
import cx from 'classnames';
import { Link as HyperLink } from 'react-router-dom';
import "./image-side-text.scss";


const ImageSideText = (section) => {

  const { align, background_color: color, 'cta-text': cta, icon, subtitle, image, link, title
  } = section.primary;

  const theLink = link && link.uid ? link.uid : '';

  const isTopLeft = align === 'Top Left';
  const isTopCenter = align === 'Top Center';
  const isTopRight = align === 'Top Right';
  const isMiddleLeft = align === 'Middle Left';
  const isMiddleCenter = align === 'Middle Center';
  const isMiddleRight = align === 'Middle Right';
  const isBottomLeft = align === 'Bottom Left';
  const isBottomCenter = align === 'Bottom Center';
  const isBottomRight = align === 'Bottom Right';

  const sliceClasses = cx({
    'slice-side-text': true,
    'slice-side-text-topleft': isTopLeft,
    'slice-side-text-topcenter': isTopCenter,
    'slice-side-text-topright': isTopRight,
    'slice-side-text-middleleft': isMiddleLeft,
    'slice-side-text-middlecenter': isMiddleCenter,
    'slice-side-text-middleright': isMiddleRight,
    'slice-side-text-bottomleft': isBottomLeft,
    'slice-side-text-bottomcenter': isBottomCenter,
    'slice-side-text-bottomright': isBottomRight,
  });


  return (
    <div className={ sliceClasses } style={ { position: 'relative', backgroundColor: color } }>
      <img width="100%" src={ image.url } alt={ image.alt } />

      { title.length > 0 && (
        <HyperLink to={ theLink } className='caption'>
          { RichText.render(title) }
          { icon && icon.url !== null && <img width="100px" src={ icon.url } alt={ icon.alt } /> }
          { subtitle && RichText.render(subtitle) }
          { RichText.render(cta) }
        </HyperLink>
      ) }

    </div>
  );
};

export default ImageSideText;
