import React from 'react';
import { RichText } from 'prismic-reactjs';

const ImageHeading = (section) => {

  const { heading, subheading, background_image: image } = section.primary;

  return (
    <div className='slice-image-heading'>
      <div className='caption-contain'>
        { RichText.render(heading) }
        { RichText.render(subheading) }
      </div>
      <img src={ image.url } alt={ RichText.asText(heading) } />
    </div>
  );
};

export default ImageHeading;
