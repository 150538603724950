import React from 'react';

const ImageFull = (section) => {

  const { image, background_color: color } = section.primary;

  return (
    <div className='slice-image-full' style={ { backgroundColor: color } }>
      <img width='100%' src={ image.url } alt={ image.alt } />
    </div>
  );
};

export default ImageFull;
