import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';

const Header = () => {
  return (
    <header>
      <div className="header-left">
        < Logo />
        <div className='dropper'>
          <div className="hover">
            About
          </div>
          <div className="children">
            <NavLink to='/our-fabrics'>Our Fabrics</NavLink>
            <NavLink to='/our-story'>Our Story</NavLink>
          </div>
        </div>
      </div>

      <div className="header-right">
        <a className="shop-link" href="https://www.amazon.com/stores/page/3D651955-A33C-47F8-9A72-41628F900461?store_ref=storeRecs_Instant_19829937011">
          Shop
        </a>
      </div>
    </header>
  )
}

export default Header;
