import React from 'react';
import './image-collage.scss';

const FourImages = ({ items, style }) => {

  return (
    <div className="content-section image-collage image-collage-all" style={ style }>
      <div className="image-collage-wrapper">
        <div className="content-section image-collage">
          <div className="image-collage-wrapper">
            <div className="image-collage-left">
              <img alt="Al1ve" src={ items[0].image.url } />
            </div>
            <div className="image-collage-right">
              <div className="image-collage-right-top">
                <img alt="Al1ve" src={ items[1].image.url } />
              </div>
              <div className="image-collage-right-bottom">
                <div className="image-collage-right-bottom-left">
                  <img alt="Al1ve" src={ items[2].image.url } />
                </div>
                <div className="image-collage-right-bottom-right">
                  <img alt="Al1ve" src={ items[3].image.url } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ThreeImages = ({ items, style }) => {

  return (
    <div className="content-section image-collage-three image-collage-all" style={ style }>
      <div className="image-collage-three-wrapper">
        <div className="image-collage-three-left">
          <div className="image-collage-three-left-top">
            <img alt="Al1ve" src={ items[0].image.url } />
          </div>
          <div className="image-collage-three-left-bottom">
            <img alt="Al1ve" src={ items[1].image.url } />
          </div>
        </div>
        <div className="image-collage-three-right">
          <img alt="Al1ve" src={ items[2].image.url } />
        </div>
      </div>
    </div>
  );
}

const ThreeImagesSwapped = ({ items, style }) => {

  return (
    <div className="content-section image-collage-three-swapped image-collage-all" style={ style }>
      <div className="image-collage-three-swapped-wrapper">
        <div className="image-collage-three-swapped-left">
          <img alt="Al1ve" src={ items[0].image.url } />
        </div>
        <div className="image-collage-three-swapped-right">
          <div className="image-collage-three-swapped-right-top">
            <img alt="Al1ve" src={ items[1].image.url } />
          </div>
          <div className="image-collage-three-swapped-right-bottom">
            <img alt="Al1ve" src={ items[2].image.url } />
          </div>
        </div>
      </div>
    </div>
  );
}


const TwoImages = ({ items, style }) => {

  return (
    <div className="content-section image-collage-two image-collage-all" style={ style }>
      <div className="image-collage-two-wrapper">
        <div className="image-collage-two-left">
          <img alt="Al1ve" src={ items[0].image.url } />
        </div>
        <div className="image-collage-two-right">
          <img alt="Al1ve" src={ items[1].image.url } />
        </div>
      </div>
    </div>
  );
}

const TwoImagesHalf = ({ items, style }) => {

  return (
    <div className="content-section image-collage-two-half image-collage-all" style={ style }>
      <div className="image-collage-two-half-wrapper">
        <div className="image-collage-two-half-left">
          <img alt="Al1ve" src={ items[0].image.url } />
        </div>
        <div className="image-collage-two-half-right">
          <img alt="Al1ve" src={ items[1].image.url } />
        </div>
      </div>
    </div>
  );
}



const ImageCollage = (section) => {

  const { items, primary: { background_color: color, layout } } = section;

  const backgroundStyle = { backgroundColor: color };

  switch (layout) {
    case 'fourImages':
      return <FourImages items={ items } style={ backgroundStyle } />
    case 'threeImages':
      return <ThreeImages items={ items } style={ backgroundStyle } />
    case 'threeImagesSwapped':
      return <ThreeImagesSwapped items={ items } style={ backgroundStyle } />
    case 'twoImages':
      return <TwoImages items={ items } style={ backgroundStyle } />
    case 'twoImagesHalf':
      return <TwoImagesHalf items={ items } style={ backgroundStyle } />
    default:
      return <h1>hello</h1>
  }
};

export default ImageCollage;




