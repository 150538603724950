import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => (

  <Link to="/" className='alive-logo'>
    <svg width="100%" height="100%" viewBox="0 0 254 49" style={ { fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 } }>
      <path d="M203.942,49l0,-48.95l49.059,0l0,10.28l-39.223,0l0,9.802l39.223,0l0,9.788l-39.223,0l0,8.788l39.223,0l0,10.292l-49.059,0Zm-28.865,0l-10.74,0l-20.475,-48.995l10.764,0l15.099,36.01l15.109,-36.009l10.758,0l-20.515,48.994Zm-110.576,-49l10.214,0l0,38.59l38.895,0l0,10.41l-49.109,0l0,-49Zm61.402,9.892l-5.091,0l0,-9.892l15.359,0l0,49l-10.268,0l0,-39.108Zm-79.145,8.203l-5.298,5.153l0,-14.569l8.847,-8.628l7.038,0l0,48.94l-10.587,0l0,-30.896Zm-46.758,30.897l36.165,-35.19l0,14.572l-21.186,20.618l-14.979,0Z" />
    </svg>
  </Link>
);

export default Logo;
