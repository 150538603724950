import React from 'react';
import NotFound from './NotFound';

import TextSection from './slices/TextSection';
import ImageHeading from './slices/ImageHeading';
import ImageSideText from './slices/ImageSideText';
import ImageFull from './slices/ImageFull';
import ImageThreeUpCaptions from './slices/ImageThreeUpCaptions';
import ImageCarousel from './slices/ImageCarousel';
import ImageCollage from './slices/ImageCollage';
import Instagram from './slices/Instagram';

export default class GenericPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      doc: null,
      notFound: false,
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  componentDidUpdate(prevProps) {
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
    if (prevProps.match.url !== this.props.match.url) {
      this.fetchPage(this.props);
      window.scrollTo(0, 0);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getByUID('page', props.match.params.uid, {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }

  render() {

    if (this.state.doc) {
      const { doc } = this.state;
      const { body } = doc.data;

      return (
        <div className='page-contain'>
          { body.map((section, index) => {

            switch (section.slice_type) {
              case 'text_section':
                return <TextSection key={ index } { ...section } />;
              case 'image_with_heading':
                return <ImageHeading key={ index } { ...section } />;
              case 'image_with_side_text':
                return <ImageSideText key={ index } { ...section } />;
              case 'full-width_image':
                return <ImageFull key={ index } { ...section } />;
              case 'three-up_images_with_captions':
                return <ImageThreeUpCaptions key={ index } { ...section } />;
              case 'image_carousel':
                return <ImageCarousel key={ index } { ...section } />;
              case 'image_collage':
                return <ImageCollage key={ index } { ...section } />;
              case 'instagram_section':
                return <Instagram key={ index } { ...section } />;
              default:
                return <h1 key={ index }>{ section.slice_type }</h1>;
            }
          }) }


        </div>
      );
    }
    if (this.state.notFound) {
      return <NotFound />;
    }
    return <h1>Loading</h1>;
  }
}
