import React from 'react';
import { RichText } from 'prismic-reactjs';

const ImageThreeUpCaptions = (section) => {

  const { caption1, caption2, caption3, image1, image2, image3 } = section.primary;

  return (
    <div className='image-three-up'>

      <div className='item'>
        <img width='100%' src={ image1.url } alt={ RichText.asText(caption1) } />
        { RichText.render(caption1) }
      </div>

      <div className='item'>
        <img width='100%' src={ image2.url } alt={ RichText.asText(caption2) } />
        { RichText.render(caption2) }
      </div>

      <div className='item'>
        <img width='100%' src={ image3.url } alt={ RichText.asText(caption3) } />
        { RichText.render(caption3) }
      </div>

    </div>
  );
};

export default ImageThreeUpCaptions;
